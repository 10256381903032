import { useState } from 'react'
import { NavLink } from 'react-router-dom'
import Hamburger from '../hamburger/hamburger'
import './header.css'

export default function Header () {
    const [open, setOpen] = useState(false)
    const [prevScrolled, setPrevScrolled] = useState(0)
    const [scrolled, setScrolled] = useState(0)
    
    function updateState () {
        setPrevScrolled(scrolled)
        setScrolled(window.pageYOffset)
    }

    useState(() => {
        window.addEventListener('scroll', updateState, { passive: true })

        return () => {
            window.removeEventListener('scroll', updateState);
        };
    }, [])

    return <>
    {(scrolled >= prevScrolled) && <div style={{backgroundColor: scrolled > (window.innerHeight*0.3) ? '#fffffff4' : 'transparent'}} className={open ? "Header Header--open" : "Header"}>
        <NavLink to="/">{/*<button onClick={()=>{setOpen(false)}} className={scrolled > (window.innerHeight*0.3)  ? "Header__logo Header__logo--smaller" : "Header__logo"}>*/}
        <svg width="30" height="30" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" style={{paddingTop: 16, paddingLeft: 20}}>
            <mask id="path-1-inside-1_6_3" fill="white">
            <path d="M20 9.97455C20 15.5958 15.767 20 10.1026 20C4.43811 20 0 15.5958 0 9.97455C0 4.35334 4.43811 0 10.1026 0C15.767 0 20 4.35334 20 9.97455Z"/>
            </mask>
            <path d="M2.02564 12.2646C2.02564 9.39618 3.99798 7.31043 6.76923 7.31043V5.31043C2.79978 5.31043 0.0256411 8.38762 0.0256411 12.2646H2.02564ZM6.76923 7.31043C8.33155 7.31043 9.85436 7.65268 10.9501 8.31781C12.0087 8.96035 12.641 9.87362 12.641 11.145H14.641C14.641 9.04372 13.5226 7.53969 11.9879 6.60812C10.4904 5.69912 8.57726 5.31043 6.76923 5.31043V7.31043ZM7.5641 11.145C7.5641 9.86043 8.18421 8.94917 9.21131 8.31315C10.2781 7.65255 11.7713 7.31043 13.3333 7.31043V5.31043C11.525 5.31043 9.6336 5.69925 8.15836 6.61277C6.64344 7.55087 5.5641 9.05691 5.5641 11.145H7.5641ZM13.3333 7.31043C16.093 7.31043 18.0256 9.38387 18.0256 12.2646H20.0256C20.0256 8.39993 17.3144 5.31043 13.3333 5.31043V7.31043ZM12.641 11.145C12.641 11.4927 12.3638 12.0711 11.7512 12.6284C11.1456 13.1793 10.4913 13.4529 10.1026 13.4529V15.4529C11.1866 15.4529 12.3015 14.8315 13.097 14.1078C13.8854 13.3907 14.641 12.3151 14.641 11.145H12.641ZM10.1026 13.4529C9.71382 13.4529 9.05954 13.1793 8.45396 12.6284C7.84132 12.0711 7.5641 11.4927 7.5641 11.145H5.5641C5.5641 12.3151 6.31974 13.3907 7.10813 14.1078C7.90359 14.8315 9.01855 15.4529 10.1026 15.4529V13.4529ZM18 9.97455C18 14.5309 14.6231 18 10.1026 18V22C16.9109 22 22 16.6606 22 9.97455H18ZM10.1026 18C5.5282 18 2 14.4768 2 9.97455H-2C-2 16.7148 3.34801 22 10.1026 22V18ZM2 9.97455C2 5.4856 5.51478 2 10.1026 2V-2C3.36143 -2 -2 3.22107 -2 9.97455H2ZM10.1026 2C14.6364 2 18 5.43165 18 9.97455H22C22 3.27503 16.8977 -2 10.1026 -2V2Z" fill="black" mask="url(#path-1-inside-1_6_3)"/>
        </svg>

        {/*</button>*/}</NavLink>
        <button className="Header__menu__button" onClick={()=>setOpen(!open)}>
            {(!open) ? <>
                {(document.body.clientWidth > 1000)
                ? <div className="Header__menu__button__text">+7 995 156 37 87 <span style={{marginLeft: 20}}>Let's work!</span></div>
                : <div className="Header__menu__icon">phone chat</div>
                }</>
            : <Hamburger open={open} setOpen={setOpen}/>}
        </button>
        {open && <div className="Header__info">
            <a className="Header__info__box" href="">
                <div className="Header__info__hello">{"->"}</div>
            </a>
            <a className="Header__info__box" href="tel:+79951563787">
                <div className="Header__info__title">phone</div>
                <div className="Header__info__value">+7 995 156 37 87</div>
            </a>
            <a className="Header__info__box" href="mailto:mail@moskovskiy.org">
                <div className="Header__info__title">mail</div>
                <div className="Header__info__value">mail@moskovskiy.org</div>
            </a>
            <a className="Header__info__box" href="https://t.me/moskovskiyorg">
                <div className="Header__info__title">telegram</div>
                <div className="Header__info__value">@moskovskiyorg</div>
            </a>
            <a className="Header__info__box" href="https://fb.me/romamoskovskiy">
                <div className="Header__info__title">facebook</div>
                <div className="Header__info__value">@romamoskovskiy</div>
            </a>
            <a className="Header__info__box" href="https://linkedin.com/in/romamoskovskiy">
                <div className="Header__info__title">linkedin</div>
                <div className="Header__info__value">@romamoskovskiy</div>
            </a>
            <a className="Header__info__box" href="https://dribbble.com/moskovskiy">
                <div className="Header__info__title">dribbble</div>
                <div className="Header__info__value">@moskovskiy</div>
            </a>
            <a className="Header__info__box" href="https://yandex.ru/maps/-/CCUiQGTHtC">
                <div className="Header__info__title">address</div>
                <div className="Header__info__value">Russia -> Estonia</div>
            </a>
        </div>}
    </div>}</>
}