import Product, { ProductDescription } from '../../components/product/product'
import Block, { Blocks } from '../../components/block/block'
import Img1 from '../../assets/iceberry/1.png'
import Img2 from '../../assets/iceberry/2.png'
import Img3 from '../../assets/iceberry/3.png'

export default function WorksIceberry () {
    return <div>
        <Product
            image={Img1}
            backgroundColor="#257C58"
            kind="Разработка веб-приложения"
            name="Логистика"
        />

        <ProductDescription
        />

        <Blocks>
            <Block
                image={Img2}
            />
            <Block
                image={Img3}
            />
        </Blocks>

    </div>
}