import './App.css'
import { BrowserRouter as Router, NavLink, Route } from 'react-router-dom'
import { useState } from 'react'
import { Link, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'

import About from './views/about'
import Lander from './views/landing'

import Header from './components/header/header'

import WorksIceberry from './views/landing/iceberry'

function App() {

  return (
    <div className="App">
      <Router>
        <Header/>
        <Route exact path='/' component={Lander} />
        <Route exact path='/about' component={About} />

        <Route exact path='/work/iceberry' component={WorksIceberry}/>
      </Router>
    </div>
  );
}

export default App;

//box-shadow: 0px 0px 1000px 1000px rgba(0, 0, 0, 0.11)
