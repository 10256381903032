import { useState } from 'react'
import { NavLink } from 'react-router-dom'
import './block.css'

import YaddaLogo from '../../assets/yadda.png'

export function Blocks (props) {
    return <div className="Blocks">
        {props.children}
    </div>
}

export function CopyrightBlock () {
    return <div className="CopyrightBlock">
       

        ©2022 Roman Nesteruk
        <br/>
        <div style={{fontSize: 10, paddingRight: 30, lineHeight: '20px'}}>
           Service might be provided by 3rd party contractors
        </div>

        <a className="CopyrightBlock__link" href="https://yadda.io"><img style={{marginTop: 50, width: '33vw', maxWidth: 100}} src={YaddaLogo}/></a> 
    </div>
}

export default function Block (props) {

    const [opened, setOpened] = useState(false)

    return <div className={"Block Block" + props.kind}>
        <img className={"Block__image Block__image" + props.kind}  src={props.image}/>
        {<div className="Block__text">{props.text}</div>}
        {false && <button onClick={()=>{setOpened(!opened)}}className='Block__expand'>add_circle_outline</button>}
        {opened && <div className="Block__text--hidden">{props.hidden}</div>}
        {props.link && <button className='Block__link' onClick={()=>window.open(props.link)}>Link <span style={{fontFamily: "Manrope"}}>{"->"}</span></button>}
    </div>
}