import './index.css'
import { useState } from 'react'

import { Link, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'

function App() {

  return (
    <>
    {/*<div className="Container Container--strict">
      <div className="Heading Heading--invert" style={{marginTop: 150, color: 'white'}}>
        
      </div>
      <div className="Heading--invert">
        <div className="Element">
            <div className="Element__icon">
              <img className="Element__icon__img" src={saas}/>
            </div>
            <div className="Element__text">Веб-страницы</div>
            <div className="Element__hint">Featureful SaaS and other industrial B2B solutions</div>
        </div>
        <div className="Element">
            <div className="Element__icon">
              <img className="Element__icon__img" src={website}/>
            </div>
            <div className="Element__text">Дизайн приложений</div>
            <div className="Element__hint">Branding, design and websites for consumer products and B2B services</div>
        </div>
        <div className="Element">
            <div className="Element__icon">
              <img className="Element__icon__img" src={mobile}/>
            </div>
            <div className="Element__text">Приложения и боты</div>
            <div className="Element__hint">Mobile cross-platform apps and social media bots</div>
        </div>
        <div className="Element">
            <div className="Element__icon">
              <img className="Element__icon__img" src={mvp}/>
            </div>
            <div className="Element__text">Пользовательский путь</div>
            <div className="Element__hint">Startup MVP for idea validation and customer research</div>
        </div>
      </div>
      </div>
  */}</>
  );
}

export default App;

//box-shadow: 0px 0px 1000px 1000px rgba(0, 0, 0, 0.11)
