import { useState } from 'react'
import { NavLink } from 'react-router-dom'
import './product.css'

export function ProductDescription (props) {
    return <div>

    </div>
}

export default function Product (props) {

    return <div className="Product" style={{backgroundColor: props.backgroundColor}}>
        <div className="Product__kind">{props.kind}</div>
        <div className="Product__name">{props.name}</div>
        <img className="Product__image" src={props.image}/>
    </div>
}